<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
             <el-option label="待审核" :value="1"></el-option>
          <el-option label="通过" :value="2"></el-option>
          <el-option label="拒绝" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addNew">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">待审核</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 2"
            >通过</el-tag
          >
           <el-tag type="danger" size="small" v-if="row.status === 3"
            >审核拒绝</el-tag
          >
           <el-tag type="danger" size="small" v-if="row.status === 4"
            >冻结</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="updateInfo(row)"
            >编辑</el-button
          >
            <el-button v-if="row.status == 1" type="success" size="small" @click="updateStatus(row,2)"
            >通过</el-button
          >
            <el-button v-if="row.status == 1"  type="warning" size="small" @click="updateStatus(row,3)"
            >拒绝</el-button
          >
          <el-button v-if="row.status == 2"  type="warning" size="small" @click="updateStatus(row,4)"
            >冻结</el-button
          >
          <el-button  type="success" size="small" @click="details(row)"
            >详情</el-button
          >
          <el-button  type="primary" size="small" @click="toScheduling(row)"
            >排期</el-button
          >
        </div>
      </template>
    </auto-table>


    <AddorEdit ref="add"></AddorEdit>
  </div>
</template>

<script>
import AddorEdit from '../components/venue/AddorEdit.vue';
export default {
  components:{
      AddorEdit
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "城市", value: "city" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: '',
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    toScheduling(row){
       this.$router.push({name:'serviceScheduling',query:{vid:row.id}})

    },
    details(row){
      this.$router.push({name:'sharedVenueDetail',query:{id:row.id}})
    },
    addNew(){
      this.$refs.add.open()
    },

    updateInfo(row) {
       this.$refs.add.open(row)
    },
    updateStatus(row,status){
        this.$post('/shared/sharedVenue/update',{id:row.id,status}).then(res=>{
          this.$message('成功')
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/shared/sharedVenue/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>